// todo: move mobile navigation/toggle icon to their own files

import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"
import {
  defaultBorder,
  black,
  above,
  defaultGridGap,
  defaultGutters,
} from "../../../global"
import { MobileNavigation } from "../../../components"

export const Header = ({ className }) => {
  const data = useStaticQuery(graphql`
    query MobileNavigationQuery {
      allStatamicGlobal {
        edges {
          node {
            navigation {
              external_url
              link_text
              page {
                slug
              }
            }
          }
        }
      }
    }
  `)

  const navigation = data.allStatamicGlobal.edges[1].node

  const [isMobileNavigationOpen, setMobileNavigationOpen] = useState(false)

  const bodyScrollToggleHandler = () => {
    // document.body.style.overflowY = isMobileNavigationOpen ? `hidden` : `scroll`

    // todo: Set up window definition for build time
    if (typeof document !== `undefined`) {
      document.body.style.overflowY = isMobileNavigationOpen
        ? `hidden`
        : `scroll`
    } else {
      return null
    }
  }

  // todo: move to new file probably
  const mobileNavigationMenuAnimation = useSpring({
    transform: isMobileNavigationOpen
      ? `translate3d(0, 0, 0)`
      : `translate3d(100%, 0, 0)`,
    onStart: () => bodyScrollToggleHandler(),
  })

  //* Hamburger menu icon animation definitions
  const toggleLineTopAnimation = useSpring({
    transform: isMobileNavigationOpen
      ? `rotate(45deg) translate3d(0px,25px,0px)`
      : `rotate(0deg) translate3d(0px,0px,0px)`,
  })
  const toggleLineMiddleAnimation = useSpring({
    transform: isMobileNavigationOpen ? `scale(0)` : `scale(1)`,
  })
  const toggleLineBottomAnimation = useSpring({
    transform: isMobileNavigationOpen
      ? `rotate(-45deg) translate3d(0px,-25px,0px)`
      : `rotate(0deg) translate3d(0px,0px,0px)`,
  })

  return (
    <header className={`navigation ${className}`}>
      <div className="navigation__content">
        <div className="navigation__content--logo">
          <Link className="navigation__content--logo--link" to="/">
            Logo
          </Link>
        </div>
        <div className="navigation__content--menu">
          {navigation.navigation.map((navigationItem, key) => (
            <Link
              key={key}
              to={`/${navigationItem.page.slug}`}
              className="navigation__content--menu--item"
            >
              {navigationItem.link_text}
            </Link>
          ))}
        </div>
        <button
          className="navigation__content--toggle"
          onClick={() => setMobileNavigationOpen(!isMobileNavigationOpen)}
        >
          <svg
            className="navigation__content--toggle--icon"
            viewBox="0 0 100 100"
          >
            <animated.line
              x1="0"
              x2="100"
              y1="25"
              y2="25"
              style={toggleLineTopAnimation}
            />
            <animated.line
              x1="0"
              x2="100"
              y1="50"
              y2="50"
              style={toggleLineMiddleAnimation}
            />
            <animated.line
              x1="0"
              x2="100"
              y1="75"
              y2="75"
              style={toggleLineBottomAnimation}
            />
          </svg>
        </button>
      </div>

      {/* //* mobile navigation - opened and closed by navigation__content--toggle above*/}
      <MobileNavigation style={mobileNavigationMenuAnimation} />
    </header>
  )
}

export default styled(Header)`
  display: grid;
  grid-column: -1 / 1;
  grid-template-columns: ${defaultGutters} 1fr ${defaultGutters};
  background: white;
  border-bottom: ${defaultBorder};
  z-index: 1;
  padding: ${defaultGridGap} 0;

  .navigation__content {
    grid-column: 2 / 3;
    display: grid;
    grid-template-columns: 400px 1fr;

    &--logo {
      font-size: 40px;
      font-weight: bold;

      &--link {
        color: ${black};
        text-decoration: none;
      }
    }

    &--menu {
      display: none;
    }

    &--toggle {
      /* reset button styles */
      outline: none;
      border: none;
      background: none;
      margin: 0;
      padding: 0;

      /* define menu icon presentation */
      z-index: 999;
      height: 35px;
      width: 35px;
      align-self: center;
      justify-self: end;

      &:hover {
        cursor: pointer;
      }
      &--icon {
        line {
          transform-origin: center;
          stroke: black;
          stroke-width: 10;
        }
      }
    }

    ${above.sm`
      &--menu {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &--item {
          color: ${black};
          text-decoration: none;
        }

        &--item:not(:first-of-type) {
          margin-left: 40px;
        }
      }
      &--toggle {
        display: none;

        &--icon {

        }
      }
    `}
  }
`
