import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { animated } from "react-spring"
import styled from "styled-components"
import { Button } from "../components"
import { above, defaultGridGap, defaultBorder, defaultGutters } from "../global"

// todo: move styles
const MobileNavigationBackground = styled(animated.div)`
  position: fixed;
  top: 0;
  right: 0;
  width: 50vw;
  min-width: 275px;
  height: 100vh;
  z-index: 99;
  display: grid;
  place-items: center;
  background-color: white;
  border: ${defaultBorder};
  padding: ${defaultGridGap} 0;

  ${above.sm`
    display: none;
  `}
`

const MobileNavigationMenu = styled.div`
  min-height: 300px;
  width: 100%;

  display: grid;
  grid-template-columns: ${defaultGutters} 1fr ${defaultGutters};
  place-items: center;
`

const MobileNavigationMenuItem = styled(Button)`
  grid-column: 2 / 3;
`

export const MobileNavigation = ({ style }) => {
  const data = useStaticQuery(graphql`
    query NavigationQuery {
      allStatamicGlobal {
        edges {
          node {
            navigation {
              external_url
              link_text
              page {
                slug
              }
            }
          }
        }
      }
    }
  `)

  const navigation = data.allStatamicGlobal.edges[1].node

  return (
    // todo: add home to mobile navigation menu
    // todo: when clicking the current route's link, need to close navigation
    <MobileNavigationBackground style={style}>
      <MobileNavigationMenu>
        {navigation.navigation.map((navigationItem, key) => (
          <MobileNavigationMenuItem
            key={key}
            to={`/${navigationItem.page.slug}`}
            modifiers="fullWidth"
          >
            {navigationItem.link_text}
          </MobileNavigationMenuItem>
        ))}
      </MobileNavigationMenu>
    </MobileNavigationBackground>
  )
}
