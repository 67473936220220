import React from "react"
import styled from "styled-components"
import { defaultGridGap, defaultSectionPadding } from "../../global"

const StyledGrid = styled.div`
  grid-column: 2 / 3;
  padding: ${({ padding }) => (padding ? padding : defaultSectionPadding)};
  display: grid;
  grid-template-columns: ${props =>
    `repeat(auto-fit, minmax(${props.min}, ${props.max ? props.max : "1fr"}))`};
  grid-gap: ${({ gap }) => (gap ? gap : defaultGridGap)};
  grid-auto-flow: dense;
`

export const Grid = ({ gap, min, max, padding, children, height }) => {
  return (
    <StyledGrid gap={gap} min={min} max={max} height={height} padding={padding}>
      {children}
    </StyledGrid>
  )
}
