import styled from "styled-components"
import Img from "gatsby-image"
import { Button } from "../elements"
import { above, black } from "../../global"

export const Card = styled.div`
  overflow: hidden;
  border: 4px solid ${black};
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
`

const CardImage = styled(Img)`
  object-fit: cover;
  width: 100%;
  height: 200px;
  ${above.md`
        width: 100%;
    `}
`

const CardContent = styled.div`
  display: grid;
  align-items: center;
  padding: 20px;
`

const CardTitle = styled.h2``

const CardSubTitle = styled.h3``

const CardBody = styled.div``

const CardActions = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 200px));
  justify-items: start;
  grid-gap: 10px;
`

const CardButton = styled(Button)`
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  border: 4px solid ${black};
  color: ${black};
`

// BEM Formatting
Card.Image = CardImage
Card.Content = CardContent
Card.Title = CardTitle
Card.SubTitle = CardSubTitle
Card.Body = CardBody
Card.Actions = CardActions
Card.Button = CardButton
