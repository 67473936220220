import styled from "styled-components"
import { applyStyleModifiers } from "styled-components-modifiers"

// todo: make modifer for centering
const LAYOUTINTRODUCTION_MODIFIERS = {
  center: () => `
    justify-self: center;
    text-align: center;
  `,
  right: () => `
    justify-self: end;
    text-align: right;
  `,
}

export const LayoutIntroduction = styled.div`
  grid-column: 2 / 3;

  ${applyStyleModifiers(LAYOUTINTRODUCTION_MODIFIERS)}
`

const LayoutIntroductionTitle = styled.h2``
const LayoutIntroductionSubTitle = styled.h3``
const LayoutIntroductionBody = styled.div``

// BEM Formatting
LayoutIntroduction.Title = LayoutIntroductionTitle
LayoutIntroduction.SubTitle = LayoutIntroductionSubTitle
LayoutIntroduction.Body = LayoutIntroductionBody
