import React from "react"
import styled from "styled-components"
import { Button } from "../../elements"
import {
  CalloutBackground,
  CalloutOverlay,
  CalloutContent,
} from "./CalloutStyles"

export const Callout = ({
  className,
  fluid,
  fullHeight,
  justifyContent = "center",
  alignItems,
  overlay,
  children,
} = {}) => {
  return (
    <CalloutBackground
      fluid={fluid}
      className={className}
      fullHeight={fullHeight}
      justifyContent={justifyContent}
      alignItems={alignItems}
    >
      <CalloutOverlay overlay={overlay} />
      <CalloutContent alignItems={alignItems}>{children}</CalloutContent>
    </CalloutBackground>
  )
}

// Define children required to be in BEM format
const CalloutTitle = styled.h2``
const CalloutSubTitle = styled.p``
const CalloutButton = styled(Button)``
const CalloutButtonIcon = styled.span``
Callout.Title = CalloutTitle
Callout.SubTitle = CalloutSubTitle
Callout.Button = CalloutButton
Callout.Button.Icon = CalloutButtonIcon
