import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import { Button } from "../elements"
import { flex, defaultGridGap, defaultBorderRadius } from "../../global"

const StyledColumn = styled.div`
  ${flex};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : `left`)};
  border: ${({ border }) => (border ? border : "none")};
  padding: ${({ padding }) => (padding ? padding : "none")};
`

export const Column = ({
  alignItems,
  justifyContent,
  textAlign,
  border,
  padding,
  children,
}) => (
  <StyledColumn
    alignItems={alignItems}
    justifyContent={justifyContent}
    textAlign={textAlign}
    border={border}
    padding={padding}
  >
    {children}
  </StyledColumn>
)

// Define children required to be in BEM format
// todo: Refactor to eliminate Gatsby image for icons - we need to be able to use SVG
const ColumnImage = styled(Img)`
  width: 100%;
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : defaultBorderRadius};
  height: ${({ height }) => (height ? height : `250px`)};
  margin-bottom: ${defaultGridGap};
`
const ColumnTitle = styled.h5``
const ColumnSubTitle = styled.h6``
const ColumnContent = styled.div``
const ColumnButton = styled(Button)``

Column.Image = ColumnImage
Column.Title = ColumnTitle
Column.SubTitle = ColumnSubTitle
Column.Content = ColumnContent
Column.Button = ColumnButton
