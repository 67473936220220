import { createGlobalStyle } from "styled-components"
import { normalize } from "polished"

// todo: Set up a conditional grayscaling method for global use

// GlobalStyle is rendered in Layout.js, and contains global theming and css resets
// Or... anything else in this component. I don't really give a hoot.
export const GlobalStyle = createGlobalStyle`
  /* Normalize CSS */
  ${normalize()}
  html {
    box-sizing: border-box;
  }

  *, *:before,  *:after {
    box-sizing: inherit;
  }
  
  body {
    /* // todo: REMOVE */
    /* Temporary */
    filter: grayscale(100);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;

    // todo: solve mobile navigation overflow a better way
    width: 100vw;
    overflow-x: hidden;
  }

  p {
    max-width: 750px;
  }
`
