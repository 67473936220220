const { hot } = require("react-hot-loader/root")

// prefer default export if available
const preferDefault = m => m && m.default || m


exports.components = {
  "component---src-templates-page-jsx": hot(preferDefault(require("/app/src/templates/page.jsx"))),
  "component---src-templates-team-member-jsx": hot(preferDefault(require("/app/src/templates/team-member.jsx"))),
  "component---cache-dev-404-page-js": hot(preferDefault(require("/app/.cache/dev-404-page.js"))),
  "component---src-pages-404-js": hot(preferDefault(require("/app/src/pages/404.js")))
}

