import styled from "styled-components"
import { Link } from "gatsby"
import { applyStyleModifiers } from "styled-components-modifiers"
import { danger, black, white, defaultBorder } from "../../global"

const BUTTON_MODIFIERS = {
  small: () => `
    font-size: .9rem;
    padding: .7rem .9rem;
  `,
  large: () => `
    font-size: 1.2rem;
    padding: 1.1rem 1.3rem;
  `,
  cancel: () => `
    background: ${danger};
  `,
  fullWidth: () => `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 450px;
  `,
  primary: () => `
    border: 4px solid ${white};
    color: ${black};
    background: ${white};
    &:hover {
      cursor: pointer;
      background: transparent;
      color: ${white};
    }
  `,
  secondary: () => `
    border: 4px solid ${white};
    color: ${white};
    background: transparent;
    &:hover {
      cursor: pointer;
      background: ${white};
      color: ${black};
    }
  `,
}

export const Button = styled(Link)`
  padding: 0.9rem 1.1rem;
  font-size: 1rem;
  border: ${defaultBorder};
  color: ${black} !important;
  text-decoration: none;
  background: transparent;
  &:hover {
    cursor: pointer;
    background: ${black};
    color: ${white} !important;
  }
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }

  ${applyStyleModifiers(BUTTON_MODIFIERS)}
`
