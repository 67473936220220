import React from "react"
import styled from "styled-components"
import { defaultGutters, defaultSectionPadding } from "../../global"

const StyledSection = styled.section`
  grid-column: -1 / 1;
  display: grid;
  grid-template-columns: ${defaultGutters} 1fr ${defaultGutters};
  padding: ${defaultSectionPadding};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : `white`};
`

export const LayoutWrapper = ({ backgroundColor, children }) => {
  return (
    <StyledSection backgroundColor={backgroundColor}>{children}</StyledSection>
  )
}
