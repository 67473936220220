import React from "react"
import styled from "styled-components"
import {
  defaultSectionPadding,
  defaultBorder,
  black,
  white,
  defaultGutters,
} from "../../../global"

// todo: Move styles into seperate css file
const StyledSection = styled.section`
  grid-column: -1 / 1;
  padding: ${defaultSectionPadding};
  min-height: 40vh;
  display: grid;
  grid-template-columns: ${defaultGutters} 1fr ${defaultGutters};
`

const StyledH2 = styled.h2`
  grid-column: 2 / 3;
`

const StyledForm = styled.form`
  grid-column: 2 / 3;
  max-width: 500px;
`

const FormGroup = styled.div`
  display: grid;
  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  label {
    font-size: 20px;
    margin-bottom: 10px;
  }

  input,
  textarea {
    border: 4px solid black;
    background: transparent;
    height: 40px;
    max-width: 100%;
  }

  textarea {
    min-height: 90px;
  }
`

const StyledButton = styled.button`
  padding: 0.9rem 1.1rem;
  font-size: 1rem;
  border: ${defaultBorder};
  background: ${black};
  color: ${white};
  text-decoration: none;
  &:hover {
    cursor: pointer;
    color: ${black};
    background: transparent;
  }
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
`

export const Contact = () => {
  return (
    <StyledSection>
      <StyledH2>Lorem Ipsum</StyledH2>
      <StyledForm>
        <FormGroup>
          <label>Name</label>
          <input type="text" />
        </FormGroup>
        <FormGroup>
          <label>Email</label>
          <input type="text" />
        </FormGroup>
        <FormGroup>
          <label>Message</label>
          <textarea></textarea>
        </FormGroup>
        <FormGroup>
          <StyledButton type="submit">submit</StyledButton>
        </FormGroup>
      </StyledForm>
    </StyledSection>
  )
}
