import { css } from "styled-components"

const size = {
  xs: 575,
  sm: 870,
  md: 960,
  lg: 1140,
}

export const above = Object.keys(size).reduce((acc, label) => {
  acc[label] = (...args) => `
    @media (min-width: ${size[label]}px) {
      ${css(...args)}
    }
  `
  return acc
}, {})

export const below = Object.keys(size).reduce((acc, label) => {
  acc[label] = (...args) => `
    @media (max-width: ${size[label]}px) {
      ${css(...args)}
    }
  `
  return acc
}, {})
