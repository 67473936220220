// TODO: Layout intro block
// TODO: Add CTA option To layouts
// TODO: Programatic padding – evenly space layouts based on previous sections background color – example: white + white = no padding-bottom
// TODO: Add modifiers to buttons
// TODO: Explore "section" block
// TODO: Refactor flex
// TODO: Convert to "fixed" image queries – currently causing flashing due to fluid resizing at run time

// packages
import React from "react"
import { graphql } from "gatsby"

// components
import {
  SEO,
  Layout,
  LayoutWrapper,
  Hero,
  Callout,
  TwoColumn,
  ThreeColumn,
  FourColumn,
  Wysiwyg,
  FiftyFifty,
  Contact,
} from "../components"

// utils
import {
  RenderColumns,
  RenderTeamMembers,
  RenderLayoutIntroduction,
  RenderLayoutCTA,
} from "./utils"

export default ({ data }) => {
  const page = data.statamicPage

  return (
    <>
      {page.seo && (
        <SEO title={page.seo.title} description={page.seo.description} />
      )}
      <Layout>
        {/* //* We receive an array of layouts – we map over the array
        //* to render each layout sequentially */}
        {page.contents.map((layout, key) => {
          //* We use if statements to determine what layout to render
          //* so that we can have more control over manipulating
          //* the data and decide how/when/what to return */}

          // Hero Layout
          if (layout.type === `hero`) {
            const {
              background_image,
              align_items,
              justify_content,
              title,
              subtitle,
              button_link,
              button_text,
              button_style,
              full_height,
            } = layout

            return (
              <Hero
                key={key}
                fluid={background_image.localFile.childImageSharp.fluid}
                fullHeight={full_height}
                alignItems={align_items}
                justifyContent={justify_content}
              >
                {title && <Hero.Title>{title}</Hero.Title>}
                {subtitle && <Hero.SubTitle>{subtitle}</Hero.SubTitle>}
                {button_link && (
                  <Hero.Button to={button_link} modifiers={button_style}>
                    {button_text}
                  </Hero.Button>
                )}
              </Hero>
            )
          }

          // Callout Layout
          if (layout.type === `callout`) {
            const {
              background_image,
              align_items,
              justify_content,
              title,
              subtitle,
              button_link,
              button_text,
              button_style,
            } = layout

            return (
              <Callout
                key={key}
                fluid={background_image.localFile.childImageSharp.fluid}
                alignItems={align_items}
                justify_content={justify_content}
              >
                {title && <Callout.Title>{title}</Callout.Title>}
                {subtitle && <Callout.SubTitle>{subtitle}</Callout.SubTitle>}
                {button_link && (
                  <Callout.Button to={button_link} modifiers={button_style}>
                    {button_text}
                  </Callout.Button>
                )}
              </Callout>
            )
          }

          // Two Column Layout
          if (layout.type === `two_column_layout`) {
            const { columns } = layout

            return <TwoColumn key={key}>{RenderColumns(columns)}</TwoColumn>
          }
          // Three Column Layout
          if (layout.type === `three_column_layout`) {
            const { columns } = layout

            return <ThreeColumn key={key}>{RenderColumns(columns)}</ThreeColumn>
          }

          // Four Column Layout
          if (layout.type === `four_column_layout`) {
            const { columns } = layout

            return <FourColumn key={key}>{RenderColumns(columns)}</FourColumn>
          }

          // TODO: Refactor – this sucks right now super stupid
          // TODO: Probably make this a section intro type of block
          // WYSIWYG Layout
          if (layout.type === `wysiwyg`) {
            const { headline, blurb, content_alignment, wysiwyg } = layout
            return (
              <Wysiwyg alignItems={content_alignment} key={key}>
                <h2>{headline}</h2>
                <p>{blurb}</p>
                <div dangerouslySetInnerHTML={{ __html: wysiwyg }}></div>
              </Wysiwyg>
            )
          }

          // Team Members
          if (layout.type === `team_members`) {
            const team = data.allStatamicTeam
            return (
              <ThreeColumn key={key}>{RenderTeamMembers(team)}</ThreeColumn>
            )
          }

          // Fifty x Fifty Layout
          if (layout.type === `fiftyfifty`) {
            const {
              title,
              text,
              button_link,
              button_text,
              image,
              full_width,
              reverse,
              layout_title,
              layout_subtitle,
              layout_introduction,
              layout_introduction_alignment,
              cta_link,
              cta_text,
            } = layout
            return (
              <LayoutWrapper>
                {(layout_title || layout_subtitle || layout_introduction) &&
                  RenderLayoutIntroduction({
                    title: layout_title,
                    subtitle: layout_subtitle,
                    body: layout_introduction,
                    modifiers: layout_introduction_alignment,
                  })}
                <FiftyFifty
                  fluid={image.localFile.childImageSharp.fluid}
                  fullWidth={full_width}
                  reverse={reverse}
                  key={key}
                >
                  {title && <FiftyFifty.Title>{title}</FiftyFifty.Title>}
                  {text && <FiftyFifty.Text>{text}</FiftyFifty.Text>}
                  {button_link && (
                    <FiftyFifty.Button to={button_link}>
                      {button_text}
                    </FiftyFifty.Button>
                  )}
                </FiftyFifty>
                {(cta_link || cta_text) &&
                  RenderLayoutCTA({
                    cta_link: cta_link,
                    cta_text: cta_text,
                    modifiers: layout_introduction_alignment,
                  })}
              </LayoutWrapper>
            )
          }

          // Contact Placeholder
          if (layout.type === `contact`) {
            return <Contact />
          }

          // TODO: Set up default if no type exists
          return `Nothing to see here`
        })}
      </Layout>
    </>
  )
}

// TODO: Break into fragments
export const query = graphql`
  query($id: String!) {
    statamicPage(id: { eq: $id }) {
      title
      contents {
        type
        background_image {
          id
          localFile {
            childImageSharp {
              fluid(maxWidth: 1440, maxHeight: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        background_color
        full_height
        full_width
        reverse
        headline
        blurb
        wysiwyg
        content_alignment
        align_items
        title
        subtitle
        layout_title
        layout_subtitle
        layout_introduction
        layout_introduction_alignment
        cta_link
        cta_style
        cta_text
        text
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 500, maxHeight: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        button_text
        button_style
        button_link
        columns {
          type
          title
          content
          align_items
          text_align
          button_link
          button_text
          button_style
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 500, maxHeight: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          is_it_an_icon
        }
      }
    }
    allStatamicTeam {
      edges {
        node {
          name
          position
          biography_excerpt
          photo {
            localFile {
              childImageSharp {
                fluid(maxWidth: 200, maxHeight: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          slug
        }
      }
    }
  }
`
