import styled from "styled-components"
import {
  flex,
  absoluteCover,
  opaqueGray,
  white,
  defaultGutters,
} from "../../../global"
import BackgroundImage from "gatsby-background-image"

export const HeroBackground = styled(BackgroundImage)`
  position: relative;
  display: grid;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : `flex-start`)};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : `center`};
  grid-template-columns: ${defaultGutters} 1fr ${defaultGutters};
  grid-column: -1 / 1;
  min-height: ${({ fullHeight }) =>
    fullHeight ? `calc(100vh - 125px)` : `55vh`};
  background-repeat: no-repeat;
  background-position: center center;
  object-fit: cover;
`

export const HeroOverlay = styled.div`
  ${absoluteCover};
  z-index: -1;
  background: ${({ overlay }) => (overlay ? overlay : opaqueGray[0])};
`

export const HeroContent = styled.div`
  grid-column: 2 / 3;
  height: 100%;
  ${flex};
  color: ${({ color }) => (color ? color : white)};
`
