import React from "react"
import styled from "styled-components"
import { Button } from "../../elements"
import { HeroBackground, HeroOverlay, HeroContent } from "./HeroStyles"

export const Hero = ({
  className,
  fluid,
  fullHeight,
  justifyContent = "center",
  alignItems,
  overlay,
  children,
} = {}) => {
  return (
    <HeroBackground
      fluid={fluid}
      className={className}
      fullHeight={fullHeight}
      justifyContent={justifyContent}
    >
      <HeroOverlay overlay={overlay} />
      <HeroContent alignItems={alignItems}>{children}</HeroContent>
    </HeroBackground>
  )
}

// Define children required to be in BEM format
const HeroTitle = styled.h1``
const HeroSubTitle = styled.p``
const HeroButton = styled(Button)``
const HeroButtonIcon = styled.span``
Hero.Title = HeroTitle
Hero.SubTitle = HeroSubTitle
Hero.Button = HeroButton
Hero.Button.Icon = HeroButtonIcon
