import React from "react"
import { WysiwygWrapper, WysiwygContent } from "./WysiwygStyles"

export const Wysiwyg = ({
  alignItems,
  justifyContent = "center",
  html,
  children,
} = {}) => {
  return (
    <WysiwygWrapper>
      {children ? (
        <WysiwygContent justifyContent={justifyContent} alignItems={alignItems}>
          {children}
        </WysiwygContent>
      ) : (
        <WysiwygContent
          justifyContent={justifyContent}
          alignItems={alignItems}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      )}
    </WysiwygWrapper>
  )
}
