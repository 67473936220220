import React from "react"
import { Column, Card } from "../../components"

export const RenderColumns = columns => {
  return columns.map((column, key) => {
    if (column.type === `column`) {
      return (
        <Column
          key={key}
          alignItems={column.align_items}
          textAlign={column.text_align}
        >
          {column.image && (
            <Column.Image
              fluid={
                column.image && column.image.localFile.childImageSharp.fluid
              }
            />
          )}
          {column.title && <Column.Title>{column.title}</Column.Title>}
          {column.subtitle && (
            <Column.SubTitle>{column.subtitle}</Column.SubTitle>
          )}
          {column.content && (
            <Column.Content
              dangerouslySetInnerHTML={{ __html: column.content }}
            />
          )}
          {column.button_text && (
            <Column.Button to={column.button_link}>
              {column.button_text}
            </Column.Button>
          )}
        </Column>
      )
    } else if (column.type === `card`) {
      return (
        <Card key={key}>
          {column.image && (
            <Card.Image
              fluid={
                column.image && column.image.localFile.childImageSharp.fluid
              }
            />
          )}
          {column.content && (
            <Card.Content>
              {column.title && <Card.Title>{column.title}</Card.Title>}
              {column.subtitle && (
                <Card.SubTitle>{column.subtitle}</Card.SubTitle>
              )}
              {column.content && (
                <Card.Body
                  dangerouslySetInnerHTML={{ __html: column.content }}
                />
              )}
              {column.button_text && (
                <Card.Actions>
                  <Card.Button to={column.button_link}>
                    {column.button_text}
                  </Card.Button>
                </Card.Actions>
              )}
            </Card.Content>
          )}
        </Card>
      )
    } else {
      return "Yikes! You seem to have a problem with your type. You might need to accomodate for a new type in RenderColumns.jsx"
    }
  })
}
