import React from "react"
import { Column } from "../../components"

export const RenderTeamMembers = team => {
  return team.edges.map((teamMember, key) => (
    <Column key={key}>
      <Column.Image
        fluid={teamMember.node.photo[0].localFile.childImageSharp.fluid}
      />
      <Column.Title>{teamMember.node.name}</Column.Title>
      <Column.SubTitle>{teamMember.node.position}</Column.SubTitle>
      <Column.Content
        dangerouslySetInnerHTML={{ __html: teamMember.node.biography_excerpt }}
      />
    </Column>
  ))
}
