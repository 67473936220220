import React, { useState } from "react"
// import styled from "styled-components"
import { useSpring, animated } from "react-spring"

// const StyledDiv = styled.div`
//   width: 30%;
//   height: 100vh;
//   background: white;
// `

export const Toggle = () => {
  const [isToggled, setToggle] = useState(false)
  const fade = useSpring({
    transform: isToggled ? "translate3d(0,0,0)" : "translate3d(0,-5px,0)",
  })

  return (
    <>
      <animated.div style={fade}>Toggle me</animated.div>
      <button onClick={() => setToggle(!isToggled)}>Toggle</button>
    </>
  )
}
