import React, { useState } from "react"
import { Button } from "../../elements"
import { Card } from "../../blocks"
import { defaultSectionPadding, defaultGridGap } from "../../../global"
import styled from "styled-components"

// todo: Move to Seperate file
const StyledSection = styled.section`
  padding: ${defaultSectionPadding};
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: ${({ gap }) => (gap ? gap : defaultGridGap)};
`

export const CardGrid = ({ initialCount, fluid, cards, buttonText }) => {
  const [count, setCount] = useState(initialCount)

  return (
    <StyledSection>
      <h1 style={{ gridColumn: "1 / -1" }}>Spicy Stuff</h1>
      {cards.slice(0, count).map(card => (
        <Card key={card.id}>
          <Card.Image fluid={fluid} alt="random image" />
          <Card.Content>
            <Card.Title>{card.title}</Card.Title>
            <Card.Text>{card.text}</Card.Text>
            <Card.Actions>
              <Card.Button>{card.button_text}</Card.Button>
            </Card.Actions>
          </Card.Content>
        </Card>
      ))}
      {count < cards.length && (
        <div style={{ gridColumn: "1 / -1" }}>
          <Button onClick={() => setCount(count + initialCount)}>
            {buttonText}
          </Button>
        </div>
      )}
    </StyledSection>
  )
}
