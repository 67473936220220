import React from "react"
import { applyStyleModifiers } from "styled-components-modifiers"
import styled from "styled-components"
import { Button } from "../../components"
import { defaultSectionPadding } from "../../global"

const LAYOUT_CTA_MODIFIERS = {
  left: () => `
    justify-self: start;
  `,
  center: () => `
    justify-self: center;
  `,
  right: () => `
    justify-self: end;
  `,
}

const StyledDiv = styled.div`
  grid-column: 2 / 3;
  padding: ${defaultSectionPadding};
`

const StyledButton = styled(Button)`
  ${applyStyleModifiers(LAYOUT_CTA_MODIFIERS)}
`

export const RenderLayoutCTA = ({ cta_link, cta_text, modifiers }) => {
  return (
    <StyledDiv>
      <StyledButton to={cta_link} modifiers={modifiers}>
        {cta_text}
      </StyledButton>
    </StyledDiv>
  )
}
