import React from "react"
import { ThreeColumnLayout } from "./ThreeColumnStyles"

export const ThreeColumn = ({ padding, gap, backgroundColor, children }) => {
  return (
    <ThreeColumnLayout
      gap={gap}
      padding={padding}
      backgroundColor={backgroundColor}
    >
      {children}
    </ThreeColumnLayout>
  )
}
