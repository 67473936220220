import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { GlobalStyle, defaultGutters } from "../global"
import { Header, Footer } from "./layouts"

// todo: move styles - but maybe not
const Grid = styled.div`
  position: relative;
  width: 100vw;
  display: grid;
  grid-template-columns: ${defaultGutters} 1fr ${defaultGutters};
`

const StyledHeader = styled(Header)`
  grid-column: -1 / 1;
`

const StyledMain = styled.main`
  grid-column: -1 / 1;

  display: grid;
  grid-template-columns: ${defaultGutters} 1fr ${defaultGutters};
`

const StyledFooter = styled(Footer)`
  grid-column: -1 / 1;
`

export const Layout = ({ children }) => {
  return (
    <Grid>
      <GlobalStyle />
      <StyledHeader />
      <StyledMain>{children}</StyledMain>
      <StyledFooter>© {new Date().getFullYear()}, PHG</StyledFooter>
    </Grid>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
