import React from "react"
import { LayoutIntroduction } from "../../components"

export const RenderLayoutIntroduction = ({
  title,
  subtitle,
  body,
  textAlign,
  modifiers,
}) => {
  return (
    <LayoutIntroduction textAlign={textAlign} modifiers={modifiers}>
      <LayoutIntroduction.Title>{title}</LayoutIntroduction.Title>
      <LayoutIntroduction.SubTitle>{subtitle}</LayoutIntroduction.SubTitle>
      <LayoutIntroduction.Body dangerouslySetInnerHTML={{ __html: body }} />
    </LayoutIntroduction>
  )
}
