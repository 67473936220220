import React from "react"
import styled from "styled-components"
import { Button } from "../../elements"
import {
  FiftyFiftyLayout,
  FiftyFiftyImage,
  FiftyFiftyContent,
} from "./FiftyFiftyStyles"

export const FiftyFifty = ({
  alignItems,
  fluid,
  fullWidth,
  justifyContent,
  reverse,
  children,
}) => {
  return (
    <>
      <FiftyFiftyLayout fullWidth={fullWidth}>
        <FiftyFiftyContent
          reverse={reverse}
          alignItems={alignItems}
          justifyContent={justifyContent}
        >
          {children}
        </FiftyFiftyContent>
        <FiftyFiftyImage fluid={fluid} />
      </FiftyFiftyLayout>
    </>
  )
}

// Define children required to be in BEM format
const FiftyFiftyTitle = styled.h2``
const FiftyFiftyText = styled.p``
FiftyFifty.Title = FiftyFiftyTitle
FiftyFifty.Text = FiftyFiftyText
FiftyFifty.Button = styled(Button)``
