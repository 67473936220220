import React from "react"
import styled from "styled-components"
import { black } from "../../../global"

// todo: Move to seperate file
const StyledFooter = styled.footer`
  background: ${black};
  min-height: 30vh;
`

const FooterContent = styled.div`
  height: 30vh;
  max-width: 85vw;
  margin: 0 auto;
  color: white;
  display: grid;
  align-items: center;
`

export const Footer = ({ children, className }) => {
  return (
    <StyledFooter className={className}>
      <FooterContent>{children}</FooterContent>
    </StyledFooter>
  )
}
