import React from "react"
import { Grid } from "../../elements"

const Gallery = ({ min, height, children, gap }) => {
  return (
    <Grid min={`${min}px`} height={`${height}px`} gap={`${gap}px`}>
      {children}
    </Grid>
  )
}

export default Gallery
