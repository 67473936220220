// packages
import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

// components
import { Layout } from "../components"

const StyledDiv = styled.div`
  grid-column: 2 / 3;
`

export default ({ data }) => {
  const teamMember = data.statamicTeam
  const teamMemberPhoto = teamMember.photo[0].localFile.childImageSharp.fixed

  return (
    <Layout>
      <StyledDiv>
        <Img fixed={teamMemberPhoto} />
        {teamMember.name && <h1>{teamMember.name}</h1>}
        {teamMember.position && <h3>{teamMember.position}</h3>}
        {teamMember.biography && (
          <div dangerouslySetInnerHTML={{ __html: teamMember.biography }}></div>
        )}
      </StyledDiv>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    statamicTeam(id: { eq: $id }) {
      name
      position
      biography
      photo {
        localFile {
          childImageSharp {
            fixed(width: 250, height: 250) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      slug
      url
    }
  }
`
