const gap = 40
// todo: Set up gutters system properly
const gutters = `calc(100vw * 0.0618)`

export const defaultGridGap = `${gap}px`
export const defaultSectionPadding = `${gap * 1.618}px 0px`
export const defaultGutters = gutters

export const padding = () => `
  padding: ${({ padding }) => (padding ? padding : defaultSectionPadding)};
`
