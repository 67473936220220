import React from "react"
import { TwoColumnLayout } from "./TwoColumnStyles"

export const TwoColumn = ({ padding, gap, children }) => {
  return (
    <TwoColumnLayout gap={gap} padding={padding}>
      {children}
    </TwoColumnLayout>
  )
}
