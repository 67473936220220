export const purple = "#524763"
export const teal = "#82d8d8"
export const danger = "tomato"
export const white = "#ffffff"
export const black = "#000000"
export const gray = [`#333333`, `#666666`, `#999999`, `#CCCCCC`]
export const opaqueGray = [
  `rgba(0,0,0,0.8)`,
  `rgba(51,51,51,0.8)`,
  `rgba(102,102,102,0.8)`,
  `rgba(153,153,153,0.8)`,
  `rgba(204,204,204,0.8)`,
]

export const social = {
  twitter: "#00acbd",
  facebook: "#3b5998",
}
