import { css } from "styled-components"
import { defaultGutters } from "../Spacing"

export const fixed = ({
  x = 0,
  y = 0,
  yProperty = "top",
  xProperty = "left",
} = {}) => css`
  position: fixed;
  ${yProperty}: ${y};
  ${xProperty}: ${x};
`

export const absolute = ({
  x = 0,
  y = 0,
  yProperty = "top",
  xProperty = "left",
} = {}) => css`
  position: absolute;
  ${yProperty}: ${y};
  ${xProperty}: ${x};
`

export const absoluteCover = () => `
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export const flex = ({ flexDirection, alignItems, justifyContent }) => `
  display: flex; 
  flex-direction: ${flexDirection ? flexDirection : `column`};
  align-items: ${alignItems ? alignItems : `flex-start`};
  justify-content: ${justifyContent ? justifyContent : `center`};
`

export const verticalCenterWithFlex = () => `
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const fullWidthGridWithGutters = () => `
  grid-column: -1 / 1;
  display: grid;
  grid-template-columns: ${defaultGutters} 1fr ${defaultGutters};
`
