import styled from "styled-components"
// import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import { below, flex, defaultGridGap } from "../../../global"

export const FiftyFiftyLayout = styled.div`
  grid-column: ${({ fullWidth }) => (fullWidth ? `-1 / 1` : `2 / 3`)};
  min-height: 30vh;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: ${({ gap }) => (gap ? gap : defaultGridGap)};
`

export const FiftyFiftyImage = styled(Img)`
  /* background-repeat: no-repeat;
  background-position: center center;
  object-fit: cover;
  min-height: 40vh; */
`

export const FiftyFiftyContent = styled.div`
  ${flex};
  order: ${({ reverse }) => (reverse ? 0 : 1)};
  ${below.sm`
    order: 1;
    `}
`
