import styled from "styled-components"
import {
  flex,
  absoluteCover,
  opaqueGray,
  white,
  defaultGutters,
} from "../../../global"
import BackgroundImage from "gatsby-background-image"

export const CalloutBackground = styled(BackgroundImage)`
  position: relative;
  display: grid;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : `flex-start`)};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : `center`};
  grid-template-columns: ${defaultGutters} 1fr ${defaultGutters};
  grid-column: -1 / 1;
  min-height: ${({ fullHeight }) => (fullHeight ? `95vh` : `35vh`)};
  background-repeat: no-repeat;
  background-position: center center;
  object-fit: cover;
`

export const CalloutOverlay = styled.div`
  ${absoluteCover};
  z-index: -1;
  background: ${({ overlay }) => (overlay ? overlay : opaqueGray[0])};
`

export const CalloutContent = styled.div`
  height: 100%;
  grid-column: 2 / 3;
  ${flex};
  color: ${({ color }) => (color ? color : white)};
`
