import styled from "styled-components"
import {
  defaultSectionPadding,
  white,
  below,
  defaultGridGap,
} from "../../../global"

export const ThreeColumnLayout = styled.section`
  grid-column: 2 / 3;
  padding: ${({ padding }) => (padding ? padding : defaultSectionPadding)};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : white};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${({ gap }) => (gap ? gap : defaultGridGap)};
  align-items: start;

  ${below.sm`
    grid-template-columns: 1fr;
  `}
`
