import styled from "styled-components"
import { defaultSectionPadding, defaultGutters } from "../../../global"

export const WysiwygWrapper = styled.div`
  display: grid;
  grid-template-columns: ${defaultGutters} 1fr ${defaultGutters};
  grid-column: -1 / 1;

  padding: ${defaultSectionPadding};
`

export const WysiwygContent = styled.div`
  grid-column: 2 / 3;

  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : `flex-start`)};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : `center`};
`
